<template>
  <div id="assigned-subjects-info" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <p class="title is-4 has-text-left">
        {{
          assignedSubjectsInfo.length > 0
            ? assignedSubjectsInfo[0].schoolClass.name
            : ''
        }}
      </p>
      <b-table id="info" :data="assignedSubjectsInfo" hoverable>
        <b-table-column field="#" label="#" width="40" numeric v-slot="props">{{
          assignedSubjectsInfo.indexOf(props.row) + 1
        }}</b-table-column>

        <b-table-column label="Educator Name" v-slot="props">{{
          props.row.educator ? props.row.educator.name : 'N/A'
        }}</b-table-column>

        <b-table-column label="Subject" v-slot="props">{{
          props.row.subject.name
        }}</b-table-column>

        <b-table-column label="Ates Count" v-slot="props">
          <div
            :class="
              props.row.ates.length >
              Object.keys(props.row.schoolClass.classGroup.atesPolicy).length -
                1
                ? 'has-background-danger has-text-white pl-1'
                : ''
            "
          >
            {{ props.row.ates.length }}
          </div>
        </b-table-column>

        <b-table-column label="Actions" width="160" v-slot="props">
          <action-buttons
            @initialize-info="initializeInfo(props.row)"
            @initialize-update="initializeUpdate(props.row)"
            @initialize-remove="initializeRemove(props.row)"
          />
        </b-table-column>
      </b-table>
    </template>
    <app-modal
      id="assigned-subject-info"
      context="remove"
      @remove="remove"
      :show-modal.sync="isModalOpen"
    />
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { crudNotification } from '../../assets/js/notification'

export default {
  data() {
    return {
      schoolClassId: null,
      pageTitle: 'Assigned Subjects Info',
      assignedSubjectsInfo: [
        {
          schoolClass: '',
        },
      ],
      id: null,
    }
  },
  methods: {
    initializeInfo(assigned_subject) {
      this.$router.push(
        `/school/${this.schoolId}/ates/${assigned_subject.id}/school_class/${assigned_subject.schoolClass.id}`
      )
    },
    initializeUpdate(assigned_subject) {
      this.$router.push(
        `/school/${this.schoolId}/assigned_subject/${assigned_subject.id}`
      )
    },
    initializeRemove(assigned_subject) {
      this.id = parseInt(assigned_subject.id)
      this.openModal()
    },
    remove() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation DeleteAssignedSubjectQuery($id: Int!) {
              deleteAssignedSubject(input: { id: $id }) {
                assignedSubject {
                  id
                }
                errors
              }
            }
          `,
          variables: {
            id: this.id,
          },
        })
        .then((response) => {
          crudNotification(
            response.data.deleteAssignedSubject.errors,
            'Successfully deleted.'
          ).then(() => {
            this.$apollo.queries.assignedSubjectsInfo.refetch()
            this.closeModal()
          })
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.schoolClassId = this.$route.params.id

    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [
      {
        name: 'Assigned Subjects',
        route: `/school/${this.schoolId}/assigned_subjects`,
      },
    ])

    this.$apollo.addSmartQuery('assignedSubjectsInfo', {
      query: gql`
        query assignedSubjectsInfo($schoolClassId: Int!) {
          assignedSubjectsInfo(schoolClassId: $schoolClassId) {
            id
            educator {
              id
              name
            }
            subject {
              id
              name
            }
            schoolClass {
              id
              name
              classGroup {
                id
                atesPolicy {
                  fa
                  sa
                  ft
                  st
                  exams
                }
              }
            }
            ates {
              id
              ateMarks {
                id
                student {
                  id
                  name
                }
                score
                marked
              }
            }
          }
        }
      `,
      variables: {
        schoolClassId: parseInt(this.schoolClassId),
      },
      fetchPolicy: 'cache-and-network',
    })
  },
}
</script>
